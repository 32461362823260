import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["copyableUrl"];

  copyUrl(e) {
    copyToClipboard(this.copyableUrlTarget.innerText);
    e.target.innerText = "Copied!";
  }
}

function copyToClipboard(str) {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
}
